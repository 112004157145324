import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../../components/layout"
import { ComiteRegionalNav } from "../../components/menus/ComiteRegionalNav"

const PvAg: React.FC = () => (
  <Layout>
    <Helmet title="PV des Assemblées Générales" />
    <div className="container">
      <div className="row mt-3">
        <div className="secondary-col">
          <ComiteRegionalNav />
        </div>
        <div className="main-col">
          <h1>PV des Assemblées Générales</h1>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Intitulé</th>
                <th scope="col">Date</th>
                <th scope="col">Documents</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Assemblées Générales Extraordinaire et Ordinaire</td>
                <td>17 février 2024</td>
                <td>
                  <a href="/pv-ag/2024-PV-AGE-AGO-0217.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> PV (pdf)
                  </a>
                  &nbsp;
                  <a href="/pv-ag/2024-RapCom AG-0217.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> Commissions (pdf)
                  </a>
                </td>
              </tr>
              <tr>
                <td>Assemblée Générale Ordinaire</td>
                <td>18 février 2023</td>
                <td>
                  <a href="/pv-ag/PV-AGO-18-02-2023.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> PV (pdf)
                  </a>
                  &nbsp;
                  <a href="/pv-ag/RapCom-AGO-18-02-2023.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> Commissions (pdf)
                  </a>
                </td>
              </tr>
              <tr>
                <td>Assemblée Générale Ordinaire</td>
                <td>19 février 2022</td>
                <td>
                  <a href="/pv-ag/PV-AGO-19-02-2022.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> PV (pdf)
                  </a>
                </td>
              </tr>
              <tr>
                <td>Assemblée Générale Ordinaire</td>
                <td>20 février 2021</td>
                <td>
                  <a href="/pv-ag/PV-AGO-20-02-2021.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> PV (pdf)
                  </a>
                </td>
              </tr>
              <tr>
                <td>Assemblée Générale Ordinaire</td>
                <td>15 février 2020</td>
                <td>
                  <a href="/pv-ag/PV-AGO-15-02-2020.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> PV (pdf)
                  </a>
                </td>
              </tr>
              <tr>
                <td>Assemblées Générales Extraordinaire et Ordinaire</td>
                <td>16 février 2019</td>
                <td>
                  <a href="/pv-ag/PV-AGE-AGO-16-02-2019.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> PV (pdf)
                  </a>
                  &nbsp;
                  <a href="/pv-ag/Dossier-AGE-AGO-16-02-2019-web.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> Dossier (pdf)
                  </a>
                </td>
              </tr>
              <tr>
                <td>Assemblée Générale Ordinaire</td>
                <td>10 février 2018</td>
                <td>
                  <a href="/pv-ag/PV-AGO-10-02-2018.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> PV (pdf)
                  </a>
                  &nbsp;
                  <a href="/pv-ag/Dossier-AGO-10-02-2018.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> Dossier (pdf)
                  </a>
                </td>
              </tr>
              <tr>
                <td>Assemblée Générale Extraordinaire</td>
                <td>14 janvier 2017</td>
                <td>
                  <a href="/pv-ag/PV-AGE-14-01-2017.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> PV (pdf)
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
)

export default PvAg
